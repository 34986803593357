@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: rubik;
  src: url("../../public/fonts/Rubik-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: rubik;
  src: url("../../public/fonts/Rubik-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: rubik;
  src: url("../../public/fonts/Rubik-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: rubik;
  src: url("../../public/fonts/Rubik-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: rubik;
  src: url("../../public/fonts/Rubik-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: rubik;
  src: url("../../public/fonts/Rubik-ExtraBold.ttf");
  font-weight: 800;
}

.text-shadow {
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.7);
}

@layer base {
  :root {
    --background: 204 33% 97%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 340, 99%, 50%;
    --primary-to: 31, 100%, 50%;
    --primary-foreground: 210 40% 98%;

    --secondary: 201 81% 29%;
    --secondary-to: 176 80% 45%;
    --secondary-foreground: 210 40% 98%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 20px;

    --shadow-main: 0px 24px 64px rgba(0, 0, 0, 0.1);

    --faq-from: #fcdfd9;
    --faq-to: #fff1c3;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

.button-gradient-primary {
  background: linear-gradient(
    90deg,
    hsl(var(--primary)) 0%,
    hsl(var(--primary-to)) 100%
  );
}

.button-gradient-secondary {
  background: linear-gradient(
    90deg,
    hsl(var(--secondary)) 0%,
    hsl(var(--secondary-to)) 100%
  );
}

.button-gradinet-primary {
  background: linear-gradient(90deg, #fe0555 0%, #ff8700 100%);
}

.gradient-text {
  background: linear-gradient(90deg, #fe0555 0%, #ff8700 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.gradient-text-hover:hover {
  -webkit-text-fill-color: inherit;
}

.radio-group-tbd {
  border: 1px solid red;
}

button.radio-group-tbd[data-state="on"] {
  border: 1px solid blue;
}

.blog h2 {
  font-weight: bold;
}

@media only screen and (max-width: 750px) {
  .shadow {
    box-shadow: none;
  }
}

@media only screen and (min-width: 751px) {
  .shadow {
    box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.1);
  }
}

/* @layer components {
    .main-heading {
        @apply text-[28px] font-bold leading-8 md: text-[42px];
    }
} */

@layer base {
  * {
    @apply border-border;
    font-family: Rubik, ui-sans-serif, system-ui, -apple-system, Segoe UI,
      Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }

  body {
    /* @apply bg-background text-foreground; */
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

@layer utilities {
  .step {
    counter-increment: step;
  }

  .step:before {
    @apply absolute inline-flex h-8 w-8 items-center justify-center rounded-full bg-muted text-center -indent-px text-base font-medium;
    @apply ml-[-41px];
    content: counter(step);
  }
}

/* @layer components {
    .main-heading {
        @apply text-3xl md: text-[42px] font-bold
    }
} */

/* @media (max-width: 640px) {
    .container {
        @apply px-4;
    }
} */

._testimonial .slick-dots li button:before {
  background: transparent;
  color: transparent;
  border: 3px solid #bed5d7;
  border-radius: 100%;
}

._testimonial .slick-dots li {
  margin: 0 10px;
}

._testimonial .slick-dots li.slick-active button:before {
  color: transparent;
  background: #bed5d7;
}

._testimonial .slick-list {
  padding-bottom: 2rem;
}

.accordion {
  background: linear-gradient(
    90deg,
    rgba(254, 5, 85, 0.15) 0%,
    rgba(255, 135, 0, 0.15) 100%
  );
  mix-blend-mode: normal;
  border: 1px solid #e8a5ad;
  box-shadow: 0px 4px 20px rgba(84, 76, 105, 0.22);
  border-radius: 15px;
}

.contact-icon {
  background: linear-gradient(
      315deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #ffffff;
  background-blend-mode: soft-light, normal;
  box-shadow: -8.90123px -8.90123px 26.7037px rgba(255, 255, 255, 0.5),
    13.3518px 13.3518px 26.7037px rgba(0, 0, 0, 0.15);
  border-radius: 32px;
}

@media (max-width: 768px) {
  ._testimonial .slick-dots li button:before {
    width: 12px;
    height: 12px;
  }

  ._testimonial .slick-dots li {
    margin: 0 5px;
  }

  .slick-dots {
    bottom: -45px !important;
  }

  .contact-icon {
    border-radius: 12px;
  }

  ._left {
    right: -6rem;
  }

  .slick-list {
    padding: 0 !important;
  }
}

.blog li {
  @apply mt-2;
}

.blog ul {
  @apply my-6 ml-6 list-disc;
}

.blog ol {
  @apply my-6 ml-6 list-disc;
}

svg {
  margin: auto;
  flex-shrink: 0;
}

._testimonial .slick-dots li button:before {
  background: transparent;
  color: transparent;
  border: 3px solid #bed5d7;
  border-radius: 100%;
}

._testimonial .slick-dots li {
  margin: 0 10px;
}

._testimonial .slick-dots li.slick-active button:before {
  color: transparent;
  background: #bed5d7;
}

._testimonial .slick-list {
  padding-bottom: 2rem;
}

.accordion {
  background: linear-gradient(
    90deg,
    rgba(254, 5, 85, 0.15) 0%,
    rgba(255, 135, 0, 0.15) 100%
  );
  mix-blend-mode: normal;
  border: 1px solid #e8a5ad;
  box-shadow: 0px 4px 20px rgba(84, 76, 105, 0.22);
  border-radius: 15px;
}

.contact-icon {
  background: linear-gradient(
      315deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #ffffff;
  background-blend-mode: soft-light, normal;
  box-shadow: -8.90123px -8.90123px 26.7037px rgba(255, 255, 255, 0.5),
    13.3518px 13.3518px 26.7037px rgba(0, 0, 0, 0.15);
  border-radius: 32px;
}

@media (max-width: 768px) {
  ._testimonial .slick-dots li button:before {
    width: 12px;
    height: 12px;
  }

  ._testimonial .slick-dots li {
    margin: 0 5px;
  }

  .slick-dots {
    bottom: -45px !important;
  }

  .contact-icon {
    border-radius: 12px;
  }

  ._left {
    right: -6rem;
  }

  .slick-list {
    padding: 0 !important;
  }
}

label {
  color: #6e798c !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
  font-size: 16px !important;
}

.gradient {
  background: linear-gradient(
    90deg,
    rgba(176, 172, 177, 1) 77%,
    rgba(255, 126, 6, 1) 77%
  );
}

.debug {
  outline: 1px solid blue;
}

.dir-ltr {
  direction: ltr;
}

.dir-rtl {
  direction: rtl;
}

.signin_with_email_btn {
  background-color: white;
  border: 1px solid lightgray;
  width: 200px;
  padding: 10px 15px;
  transition: 0.3s ease;
  cursor: pointer;
}

.signin_with_email_btn:hover {
  background-color: #eaeaea;
}

.signin_with_email_input {
  margin-bottom: 10px;
}

.signin_with_email_input input {
  outline: none;
  width: 100%;
  border: 1px solid lightgray;
  padding: 10px 15px;
}

@media only screen and (max-width: 600px) {
  .steps-container-width {
    width: calc(650px - 50vw);
  }
}

@media only screen and (max-width: 768px) {
  .DesktopBackgroundImage {
    background-image: none !important;
  }
}

.newline {
  white-space: pre-line;
}
.text-page {
  @apply px-0 py-20 md:px-40;
  white-space: pre-line;
}

.text-page h1 {
  @apply my-2 text-2xl font-bold md:text-4xl;
  text-align: center;
}

.text-page h2 {
  @apply my-2 text-xl font-bold md:text-2xl;
}

.text-page a {
  @apply font-bold;
  @apply text-blue-600;
  @apply decoration-2 underline-offset-4 hover:underline;
}

/* Terms nested list items numbering in decimal points starts */
.text-page ol {
  counter-reset: item;
}
.text-page li {
  display: block;
}
.text-page ol > li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}

.text-page h2::before {
  content: "\A";
}

.text-page h2::after {
  content: "\A\A";
}

.text-page #privacy::before {
  content: "\A";
}

/* Terms nested list items numbering in decimal points ends */
.btn-bg-dark-blue,
.btn-bg-dark-blue:hover {
  background: #212429;
}

.orange-text-gradient {
  background: -webkit-linear-gradient(#ff0951, #ff9200);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.orange-text-gradient::before {
  content: ""; /* Required for pseudo-elements */
  position: absolute; /* Position the underline */
  bottom: 0; /* Place the underline at the bottom */
  left: 0; /* Align the underline to the left */
  width: 100%; /* Full width of the container */
  height: 2px; /* Adjust the height of the underline as needed */
  background: -webkit-linear-gradient(
    left,
    #ff0951,
    #ff9200
  ); /* Gradient for the underline */
}

/* Slick Carousel CSS */
.slick-next {
  right: -30px !important;
  left: auto !important;
  z-index: 2;
}

.slick-prev {
  left: -30px !important;
  right: auto !important;
  z-index: 2;
}

[dir="rtl"] .slick-prev:before {
  content: "" !important;
  background-image: url("/images/left-arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 12px;
  height: 42px;
}

[dir="rtl"] .slick-next:before {
  content: "" !important;
  background-image: url("/images/right-arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 12px;
  height: 42px;
}

.css-9lo51-MuiDrawer-docked-RaSidebar-root + div {
  max-width: calc(100vw - 18em);
  overflow: auto;
}

.css-rraw3v-MuiDrawer-docked-RaSidebar-root + div {
  max-width: calc(100vw - 5em);
  overflow: auto;
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
  .second-card {
    margin-right: -200px;
  }
}

@layer components {
  .scrollbar-thin::-webkit-scrollbar {
    width: 6px; /* Adjust the width of the scrollbar */
    height: 6px; /* Adjust the height of the scrollbar */
  }
  .scrollbar-thin::-webkit-scrollbar-track {
    @apply rounded bg-[#f1f1f1];
  }
  .scrollbar-thin::-webkit-scrollbar-thumb {
    @apply rounded bg-[#c1c1c1];
  }
  .scrollbar-thin::-webkit-scrollbar-thumb:hover {
    @apply bg-[#c1c1c1];
  }
}
